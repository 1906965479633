
    import has from 'lodash/has';
    import { mapState, mapActions } from 'vuex';
    import datalayer from '@/utils/datalayer';
    import { jobData } from '@/utils/jobData';

    export default {
        computed: {
            ...mapState({
                $_search: state => state.search,
            }),
        },

        methods: {
            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
                $_pushSeekerJobSearchEvent: 'pushSeekerJobSearchEvent',
            }),

            jobSearchDataLayerPush() {
                const jobsData = {};
                if (has(this.$_search, 'searchPayload.search_results.jobs')) {
                    this.$_search.searchPayload.search_results.jobs.forEach((o, index) => {
                        jobsData[o.id] = {
                            site_position: 'search_body',
                            job_page: this.page,
                            job_position: index + 1,
                            ...jobData(o),
                        };
                    });

                    datalayer({
                        c1_datalayer_source: 'c1',
                        jobs: jobsData,
                    });
                }
                const searchFilters = this.$_search.searchPayload.search_filters;
                const searchResults = this.$_search.searchPayload.search_results;
                const data = {
                    event: 'job_search',
                    search_keyword: searchFilters?.search_keywords || '',
                    search_njt_id: '',
                    search_njt: '',
                    search_location: '',
                    search_location_id: '',
                    s_loc_type: '',
                    s_loc_normalised: '',
                    s_loc_suburb_id: '',
                    s_loc_suburb_name: '',
                    s_loc_area_id: '',
                    s_loc_area_name: '',
                    s_loc_region_id: '',
                    s_loc_region_name: '',
                    s_loc_state_id: '',
                    s_loc_state_name: '',
                    s_loc_country_id: '',
                    s_loc_country_name: '',
                    search_occupation: '',
                    search_occupation_id: '',
                    search_category: '',
                    search_category_id: '',
                    search_job_type: '',
                    search_job_type_id: '',
                    search_results_count: '',
                };
                if (has(searchFilters, 'categories') && has(searchFilters.categories[0], 'occupations') && searchFilters.categories[0].occupations.length) {
                    Object.assign(data, {
                        search_occupation: searchFilters.categories[0].occupations[0].name,
                        search_occupation_id: searchFilters.categories[0].occupations[0].id,
                    });
                }

                if (has(searchFilters, 'location') && has(searchFilters.location, 'id')) {
                    Object.assign(data, {
                        search_location: searchFilters.location.label,
                        search_location_id: searchFilters.location.id,
                        s_loc_type: searchFilters.location.type,
                        s_loc_normalised: searchFilters.location.label,
                        s_loc_suburb_id: searchFilters.location.suburb_location_id,
                        s_loc_suburb_name: searchFilters.location.suburb_name,
                        s_loc_area_id: searchFilters.location.area_location_id,
                        s_loc_area_name: searchFilters.location.area_name,
                        s_loc_region_id: searchFilters.location.region_location_id,
                        s_loc_region_name: searchFilters.location.region_name,
                        s_loc_state_id: searchFilters.location.state_location_id,
                        s_loc_state_name: searchFilters.location.state_name,
                        s_loc_country_id: searchFilters.location.country_location_id,
                        s_loc_country_name: searchFilters.location.country_name,
                    });
                }

                if (has(searchFilters, 'categories') && searchFilters.categories.length) {
                    Object.assign(data, {
                        search_category: searchFilters.categories[0].name,
                        search_category_id: searchFilters.categories[0].id,
                    });
                }

                if (has(searchFilters, 'job_type') && searchFilters.job_type.length) {
                    Object.assign(data, {
                        search_job_type: searchFilters.job_type[0].value,
                        search_job_type_id: searchFilters.job_type[0].id,
                    });
                }

                if (has(searchResults, 'job_count')) {
                    Object.assign(data, {
                        search_results_count: searchResults.job_count,
                    });
                }

                if (has(searchFilters, 'sort_by')) {
                    Object.assign(data, {
                        search_sort_by: searchFilters.sort_by !== 'activates_at' ? 'relevance' : searchFilters.sort_by,
                    });
                }

                if (has(searchFilters, 'page')) {
                    Object.assign(data, {
                        pagination_page: parseInt(searchFilters.page, 10),
                    });
                }

                if (has(searchFilters, 'job_title') && searchFilters.job_title.length) {
                    Object.assign(data, {
                        search_njt_id: parseInt(searchFilters.job_title[0].id),
                        search_njt: searchFilters.job_title[0].title,
                    });
                } else if (has(searchFilters, 'parsed.job_title') && searchFilters.parsed.job_title.length) {
                    Object.assign(data, {
                        search_njt_id: parseInt(searchFilters.parsed.job_title[0].id),
                        search_njt: searchFilters.parsed.job_title[0].title,
                    });
                }
                if (has(searchFilters, 'parsed.occupations') && searchFilters.parsed.occupations.length) {
                    Object.assign(data, {
                        search_occupation_id: parseInt(searchFilters.parsed.occupations[0].id),
                        search_occupation: searchFilters.parsed.occupations[0].name,
                    });
                }
                datalayer(data);
            },

            jobSearchEventPush() {
                const data = {
                    event: 'job_search',
                };
                if (has(this.$_search, 'searchPayload.search_results.jobs')) {
                    data.job_uuids = this.$_search.searchPayload.search_results.jobs.map(o => o.uuid);
                }
                if (has(this.$_search, 'searchPayload.search_filters')) {
                    data.search_filters = this.$_search.searchPayload.search_filters;
                }
                // console.log(data);
                this.$_pushEvent(data);
                this.$_pushSeekerJobSearchEvent(data);
            },
        },
    };
