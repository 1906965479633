
    import numeral from 'numeral';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';

    dayjs.extend(utc);

    export default {
        filters: {
            toNow(value) {
                if (!value) { return ''; }
                return dayjs.utc(value).local().toNow();
            },

            fromNow(value) {
                if (!value) { return ''; }
                return dayjs.utc(value).local().fromNow();
            },

            dateFormat(value, format) {
                if (!value) { return ''; }
                return dayjs.utc(value).local().format(format);
            },

            numeral(value, format) {
                if (!value) { return 0; }
                return numeral(value).format(format);
            },
        },
    };
