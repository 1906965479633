
    import debounce from 'lodash/debounce';
    import has from 'lodash/has';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import datalayer from '@/utils/datalayer';

    export default {
        data() {
            return {
                loader: {
                    search_jobs: false,
                },
            };
        },

        methods: {
            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
            }),

            ...mapActions('search', {
                $_getSearchPageMetaData: 'getSearchPageMetaData',
            }),

            ...mapGetters({
                $_currentAppUrl: 'currentAppUrl',
            }),

            ...mapMutations('search', {
                $_SET: 'SET',
                $_SET_SEARCH_OBJECT: 'SET_OBJECT',
            }),

            isSearchResultPage(url) {
                if (this.$route.name === 'default-search-results') {
                    return true;
                }
                if (this.$route.name === 'home' && this.$platformCode !== 'careerone') {
                    return true;
                }
                if ((url.search('/br_') > 0) && (this.$route.name === 'company-search-results' || this.$route.name === 'company-article')) {
                    return true;
                }
                return false;
            },

            // eslint-disable-next-line
            searchByFilter: debounce(async function () {
                this.loader.search_jobs = true;

                const searchFilter = { ...this.$_search.searchPayload.search_filters, locale: 'AU' };
                this.$store.dispatch('job/searchJobsByFilter', searchFilter).then((response) => {
                    this.$store.commit('search/SET_OBJECT', { key: 'searchPayload', value: response });
                    this.$store.commit('search/SET', { key: 'initSearchData', value: true });
                    this.$store.commit('search/SET', { key: 'searchBlurbText', value: false });
                    const canonicalURL = response.meta.url.replace('/', '');
                    if (this.isSearchResultPage(canonicalURL) && this.$device.isDesktop) {
                        window.history.pushState({}, document.title, `${this.$siteUrl}${canonicalURL}`);
                        window.document.title = response.meta.title;
                        this.$store.commit('search/SET_SEARCH_PAGE_META_TITLE', response.meta.h1);
                        this.jobSearchDataLayerPush();
                        this.jobSearchEventPush();
                    } else {
                        const query = this.$route.query || {};
                        this.$router.push({
                            path: response.meta.canonical_url,
                            query: {
                                ...query,
                            },
                        });
                    }
                }).catch((e) => {
                    if (e.response && (e.response.status === 404 || e.response.status === 422)) {
                        window.location.href = `${this.$siteUrl}/not-found`;
                    }
                }).finally(() => {
                    this.loader.search_jobs = false;
                });
            }, 350),

            jobSearchClickDataLayerPush() {
                const searchFilters = this.$_search.searchPayload.search_filters;
                const data = {
                    event: 'job_search_click',
                    search_keyword: searchFilters ? searchFilters.search_keywords : '',
                    search_njt_id: '',
                    search_njt: '',
                    search_location: '',
                    search_location_id: '',
                    s_loc_type: '',
                    s_loc_normalised: '',
                    s_loc_suburb_id: '',
                    s_loc_suburb_name: '',
                    s_loc_area_id: '',
                    s_loc_area_name: '',
                    s_loc_region_id: '',
                    s_loc_region_name: '',
                    s_loc_state_id: '',
                    s_loc_state_name: '',
                    s_loc_country_id: '',
                    s_loc_country_name: '',
                    search_occupation: '',
                    search_occupation_id: '',
                    search_category: '',
                    search_category_id: '',
                    search_job_type: '',
                    search_job_type_id: '',
                    search_results_count: '',
                };
                if (has(searchFilters, 'categories') && has(searchFilters.categories[0], 'occupations') && searchFilters.categories[0].occupations.length) {
                    Object.assign(data, {
                        search_occupation: searchFilters.categories[0].occupations[0].name,
                        search_occupation_id: searchFilters.categories[0].occupations[0].id,
                    });
                }

                if (has(searchFilters, 'location') && has(searchFilters.location, 'id')) {
                    Object.assign(data, {
                        search_location: searchFilters.location.label,
                        search_location_id: searchFilters.location.id,
                        s_loc_type: searchFilters.location.type,
                        s_loc_normalised: searchFilters.location.label,
                        s_loc_suburb_id: searchFilters.location.suburb_location_id,
                        s_loc_suburb_name: searchFilters.location.suburb_name,
                        s_loc_area_id: searchFilters.location.area_location_id,
                        s_loc_area_name: searchFilters.location.area_name,
                        s_loc_region_id: searchFilters.location.region_location_id,
                        s_loc_region_name: searchFilters.location.region_name,
                        s_loc_state_id: searchFilters.location.state_location_id,
                        s_loc_state_name: searchFilters.location.state_name,
                        s_loc_country_id: searchFilters.location.country_location_id,
                        s_loc_country_name: searchFilters.location.country_name,
                    });
                }

                if (has(searchFilters, 'categories') && searchFilters.categories.length) {
                    Object.assign(data, {
                        search_category: searchFilters.categories[0].name,
                        search_category_id: searchFilters.categories[0].id,
                    });
                }

                if (has(searchFilters, 'job_type') && searchFilters.job_type.length) {
                    Object.assign(data, {
                        search_job_type: searchFilters.job_type[0].value,
                        search_job_type_id: searchFilters.job_type[0].id,
                    });
                }

                if (has(searchFilters, 'sort_by')) {
                    Object.assign(data, {
                        search_sort_by: searchFilters.sort_by !== 'activates_at' ? 'relevance' : searchFilters.sort_by,
                    });
                }

                if (has(searchFilters, 'page')) {
                    Object.assign(data, {
                        pagination_page: parseInt(searchFilters.page, 10),
                    });
                }

                if (has(searchFilters, 'parsed.job_title') && searchFilters.parsed.job_title.length) {
                    Object.assign(data, {
                        search_njt_id: parseInt(searchFilters.parsed.job_title[0].id),
                        search_njt: searchFilters.parsed.job_title[0].title,
                    });
                }

                if (has(searchFilters, 'parsed.occupations') && searchFilters.parsed.occupations.length) {
                    Object.assign(data, {
                        search_occupation_id: parseInt(searchFilters.parsed.occupations[0].id),
                        search_occupation: searchFilters.parsed.occupations[0].name,
                    });
                }

                datalayer(data);
            },

            jobSearchClickPushEvent() {
                const data = {
                    event: 'job_search_click',
                };

                if (has(this.$_search, 'searchPayload.search_filters')) {
                    data.search_filters = this.$_search.searchPayload.search_filters;
                }

                this.$_pushEvent(data);
            },
        },
    };
