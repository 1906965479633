
    import { mapState, mapActions } from 'vuex';
    import has from 'lodash/has';
    import Filters from '@/components/Utils/Filters';
    import SearchPushUrlMixin from '@/components/Search/SearchPushUrlMixin';
    import SearchDataLayerEventMixin from '@/components/Search/SearchDataLayerEventMixin';
    import { Bus } from '@/utils/bus';

    export default {
        name: 'SearchMixin',

        mixins: [SearchPushUrlMixin, SearchDataLayerEventMixin, Filters],

        data() {
            return {
                currentPage: 1,
                jobsPerPage: 20,
                hasTopAds: false,
                hasSidebarAdsRendered: false,
                isEducationSlideWidgetVisible: false,
                isRelatedSearchTabsVisible: false,
                isJobMatchesOnboardingVisible: false,
            };
        },

        computed: {
            ...mapState({
                $_appInfo: state => state.appInfo,
                $_me: state => state.me.user,
                $_search: state => state.search,
                $_searchPayload: state => state.search.searchPayload,
                $_jobsSearchFilters: state => state.search.searchPayload.search_filters,
                $_jobsCount: state => state.search.searchPayload?.search_results?.job_count,
                $_jobs: state => state.search.searchPayload?.search_results?.jobs,
                $_searchPageTemplate: state => state.search.searchPageTemplate,
                $_renderedAdIds: state => state.ads.renderedAdIds,
            }),
            hasSidebarAds() {
                return this.$device.isDesktop && (this.hasSidebarAdsRendered || this.$adsLayout);
            },
            hasLocationWarning() {
                return has(this.$_searchPayload, 'warning') && has(this.$_searchPayload.warning, 'bad_location');
            },

            isMobile() {
                return this.$device.isMobile || this.$mq === 'sm';
            },

            hasWarningMessage() {
                // eslint-disable-next-line
                return this.$_search?.searchPayload?.warning_message;
            },

            getBlurbText() {
                const blurbText = this.$_search.pageMetaData.blurb;
                if (blurbText) {
                    return blurbText.replace('https://www.careerone.com.au/', this.$siteUrl);
                } else {
                    return '';
                }
            },

            canShowBlurbText() {
                return this.$_search.searchBlurbText && this.$route.name !== 'home' && !this.$route.query.utm_source;
            },

            getPagingInfo() {
                let countFrom = 1;
                let countTo = this.jobsPerPage;
                const currentPage = this.$_jobsSearchFilters.page;
                const numberOfJobsText = this.$_jobsCount > 1 ? 'jobs' : 'job';
                if (this.jobsPerPage >= this.$_jobsCount) {
                    return `${this.$_jobsCount} ${numberOfJobsText}`;
                } else {
                    if (currentPage > 1) {
                        countFrom = ((currentPage - 1) * this.jobsPerPage) + 1;
                        countTo = currentPage * countTo;
                        if (countTo > this.$_jobsCount) {
                            countTo = this.$_jobsCount;
                        }
                    }
                    return `${countFrom} - ${countTo} of ${this.$_jobsCount} ${numberOfJobsText}`;
                }
            },
        },

        watch: {
            $_renderedAdIds(v) {
                if (this.hasSidebarAds) {
                    return;
                }
                const sidebarAdIds = ['c1AdDesktopHalfPage1', 'c1AdDesktopHalfPage2', 'c1AdDesktopHalfPage3', 'c1AdDesktopHalfPage4'];
                const topAdIds = ['c1AdDesktopTopBanner'];
                for (const ad of sidebarAdIds) {
                    this.hasSidebarAdsRendered = this.$_renderedAdIds.includes(ad);
                    if (this.hasSidebarAdsRendered) {
                        break;
                    }
                }

                for (const ad of topAdIds) {
                    this.hasTopAds = this.$_renderedAdIds.includes(ad);
                    if (this.hasTopAds) {
                        break;
                    }
                }
            },
        },

        created() {
            if (process.browser) {
                Bus.$on('onToggleJobVisibility', (data) => {
                    this.$store.commit('search/TOGGLE_JOB_VISIBILITY', data);
                });
            }

            if (this.$device.isMobile) {
                this.$store.commit('search/SET', { key: 'showSearchBar', value: false });
            }
        },

        beforeDestroy() {
            Bus.$off('onToggleJobVisibility');
        },

        methods: {
            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
                $_pushSeekerJobSearchEvent: 'pushSeekerJobSearchEvent',
            }),

            changePage(page) {
                this.$store.commit('search/SET_SEARCH_FILTERS', { key: 'page', value: page, preventSearch: true });
                this.searchByFilter();
                this.$velocity(
                    document.getElementById('searchPageDefault'),
                    'scroll',
                    {
                        duration: 600,
                        offset: -150,
                    });
            },

            onSort() {
                this.searchByFilter();
            },

            onSearch() {
                this.searchByFilter();
            },

            onSearchClick() {
                this.searchByFilter();
                this.jobSearchClickDataLayerPush();
                this.jobSearchClickPushEvent();
            },

            activateSearchFilters() {
                this.$store.commit('search/SET', { key: 'activeSearchFilter', value: true });
                document.body.classList.add('body--with-fullscreen-modal');
                const inputKeywordSearchEl = document.getElementById('inputKeywordSearch');
                if (inputKeywordSearchEl && this.$device.isDesktop) {
                    inputKeywordSearchEl.focus();
                }
            },

            onRelatedSearchTabsVisible(isVisible) {
                if (isVisible) {
                    this.isRelatedSearchTabsVisible = true;
                }
            },

            onEducationSlideVisible(isVisible) {
                if (isVisible) {
                    this.isEducationSlideWidgetVisible = true;
                }
            },

            onJobMatchesOnboardingVisible(isVisible) {
                if (isVisible) {
                    this.isJobMatchesOnboardingVisible = true;
                }
            },

            getSlicedJobs(list, startIndex, endIndex) {
                return endIndex ? list.slice(startIndex, endIndex) : list.slice(startIndex);
            },

            canShowMoreThan(resultIndex) {
                return this.$_searchPayload.search_results && this.$_searchPayload.search_results.jobs.length > resultIndex;
            },
        },
    };
